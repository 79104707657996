@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
    height: calc(100vh - var(--topbarHeightDesktop));
    max-height: none;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 80px;

  @media (--viewportMedium) {
    justify-content: center;
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.easelySectionWrapper {
  width: 100%;
}

.wrapperClass {
  width: 100%;
  background-color: var(--matterColorDark);
  position: relative;
}

.sectionWrapper {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 0px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}


@media (min-aspect-ratio: 16/9) {
  /*.firstBox{*/
  /*  margin-top: 230px*/
  /*}*/
}
@media (max-aspect-ratio: 16/9) and (max-width: 768px) {
  .firstBox {
    margin-top: 25vh
  }
}

